<template>
  <div>
    <h2>Plant Performance</h2>

    <div v-if="!this.datacollection">No data</div>

    <div v-else class="d-flex">
      <div class="flex-fill">
        <BarChart :chart-data="datacollection" :options="chartOptions" />
      </div>

      <div class="filters ml-2">
        <b-form-group label="Plants" v-slot="{ ariaDescribedby }" class="mb-4">
          <b-form-checkbox-group
            :aria-describedby="ariaDescribedby"
            :options="filter_plants_modified"
            @change="update"
            name="plants"
            stacked
            switches
            v-model="plants"
          />
        </b-form-group>

        <b-form-group label="Scenario (Economy)" v-slot="{ ariaDescribedby }" class="mb-4">
          <b-form-checkbox-group
            :aria-describedby="ariaDescribedby"
            :options="filterScenarios"
            @change="update"
            name="scenarios"
            stacked
            switches
            v-model="scenarios"
          />
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import px from 'vue-types'

import BarChart from '@/charts/BarChart.js'
import mixpanel from '@/mixpanel.js'

export default {
  components: {
    BarChart,
  },
  props: {
    statsData: px.object,
  },
  data() {
    return {
      datacollection: null,
      plants: [],
      scenarios: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          align: 'start',
        },
        tooltips: {
          titleFontSize: 16,
          bodyFontSize: 12,
        },
        scales: {
          xAxes: [],
          yAxes: [],
        },
      },
    }
  },

  computed: {
    filter_plants_modified() {
      return this.filterPlants.map(f => ({
        text: f ? `Plant ${f}` : 'No plants',
        value: f,
      }))
    },
    filterPlants() {
      return [
        ...new Set((this.statsData.plantPerformance || []).map(e => e.plant_site).sort((a, b) => (a > b ? 1 : -1))),
      ]
    },
    filterScenarios() {
      return [
        { value: 'P', text: 'Pistol' },
        { value: '$', text: 'Eco 0-5k' },
        { value: '$$', text: 'Semi-eco 5-10k' },
        { value: '$$$', text: 'Semi-buy 10-20k' },
        { value: '$$$$', text: 'Full-buy 20k+' },
      ].map(s => {
        const unique_vals = [...new Set((this.statsData.plantPerformance || []).map(e => e.eco_type))]
        return {
          ...s,
          disabled: !unique_vals.includes(s.value),
        }
      })
    },
  },

  mounted() {
    // select all filters by default
    this.plants = this.filterPlants
    this.scenarios = this.filterScenarios.filter(s => !s.disabled).map(s => s.value)

    // set the max Y value
    const suggestedMax = this.statsData.plantPerformance
      .reduce((prev, el, i, arr) => {
        if (el.round_millis !== arr[i - 1]?.round_millis) {
          prev.push(el.kills)
        } else {
          prev[prev.length - 1] = prev[prev.length - 1] + arr[i].kills
        }
        return prev
      }, [])
      .reduce((prev, el) => (prev < el ? el : prev), 0)

    // update options
    this.chartOptions.scales.yAxes.push({
      ticks: {
        precision: 0,
        suggestedMax,
      },
    })

    this.update_chart()
  },

  methods: {
    update() {
      this.update_chart()

      mixpanel.track_plant_performance_filters({
        plants: this.plants,
        scenarios: this.scenarios,
      })
    },

    update_chart() {
      const labels = []

      for (let index = 0; index <= 140; index = index + 5) {
        labels.push(index)
      }

      const dataset_kills = {
        label: 'Kills',
        type: 'line',
        borderColor: '#c80a19',
        fill: false,
        data: labels.map(time => {
          let kills = 0
          this.statsData.plantPerformance.forEach(pp => {
            if (pp.round_millis === time * 1000) {
              kills +=
                this.plants.indexOf(pp.plant_site) !== -1 && this.scenarios.indexOf(pp.eco_type) !== -1 ? pp.kills : 0
            }
          })
          return kills
        }),
      }

      const plant_colors = {
        'No Plants': '#bababa',
        'Plants A': '#38bfb2',
        'Plants B': '#38bf64',
        'Plants C': '#bfb138',
      }

      const dataset_plants = this.filterPlants.map(plant_site => {
        const label = plant_site === '' ? 'No Plants' : `Plants ${plant_site}`

        return {
          label,
          backgroundColor: plant_colors[label],
          fill: true,
          data: labels.map(time => {
            let plants = 0
            this.statsData.plantPerformance.forEach(pp => {
              if (pp.round_millis === time * 1000) {
                plants +=
                  plant_site === pp.plant_site &&
                  this.plants.indexOf(pp.plant_site) !== -1 &&
                  this.scenarios.indexOf(pp.eco_type) !== -1
                    ? pp.rounds_planted
                    : 0
              }
            })
            return plants
          }),
        }
      })

      this.datacollection = {
        labels,
        datasets: [dataset_kills, ...dataset_plants],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.small {
  max-width: 600px;
  margin: 150px auto;
}

.filters {
  min-width: 320px;
}
</style>
