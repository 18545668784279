<template>
  <div>
    <h2>Compositions</h2>
    <AppTable
      :items="teamCompositions"
      :fields="compositionsFields"
      tbody-tr-class="cursor-pointer"
      @row-clicked="toggleDetails"
    >
      <template #cell(agentIds)="field">
        <div class="composition-cell">
          <BIconCaretRightFill :class="field.detailsShowing ? 'expanded' : 'collapsed'" class="toggle-details-icon" />
          <AgentComposition :agent_ids="field.item.agentIds" size="sm" />
        </div>
      </template>
      <template #row-details="row">
        <AppTable :items="row.item.players" :fields="playersFields">
          <template #cell(player)="field">
            <router-link v-if="field.item.id" :to="`/player/${field.item.id}/stats/${selected_map_id}`">
              {{ field.item.game_name }}
            </router-link>
            <span v-else>
              {{ field.item.game_name }}
            </span>
          </template>

          <template #cell(agent)="field">
            <img class="agent-icon" :src="field.item.agent_display_icon_url" :alt="field.item.agent_name" />
            <span>
              {{ field.item.agent_name }}
            </span>
          </template>
        </AppTable>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BIconCaretRightFill } from 'bootstrap-vue'
import px from 'vue-types'
import { mapGetters } from 'vuex'

import AppTable from '@/components/generic/Table.vue'
import AgentComposition from '@/components/UI/AgentComposition.vue'
import percentage from '@/filters/percentage.js'
import getCompositionHash from '@/utils/agentCompositionHash'

export default {
  name: 'CompositionsStats',
  components: {
    AppTable,
    AgentComposition,
    BIconCaretRightFill,
  },
  props: {
    statsData: px.object.def({}),
  },
  computed: {
    ...mapGetters({
      selected_map_id: 'team/selected_map_id',
    }),
    teamCompositions() {
      const comps = Object.values(
        this.statsData.compositions.reduce((acc, curr) => {
          if (curr) {
            const hash = getCompositionHash(curr.agentIds)
            if (!acc[hash]) {
              acc[hash] = {
                agentIds: curr.agentIds,
                picks: 1,
                wins: curr.win ? 1 : 0,
                losses: curr.win ? 0 : 1,
                players: curr.players,
                _showDetails: false,
              }
            } else {
              acc[hash].picks++
              acc[hash].wins += curr.win ? 1 : 0
              acc[hash].losses += curr.win ? 0 : 1
              acc[hash].players = [
                ...acc[hash].players,
                ...curr.players.filter(
                  player =>
                    !acc[hash].players.some(
                      existingPlayer => existingPlayer.id === player.id && existingPlayer.agent_id === player.agent_id
                    )
                ),
              ]
            }
          }
          return acc
        }, {})
      )
      if (comps && comps.length > 0) {
        comps[0]._showDetails = true
      }
      return comps
    },
  },
  data: () => {
    return {
      compositionsFields: [
        { key: 'agentIds', label: 'Composition', class: 'text-left' },
        { key: 'picks', label: 'Picks' },
        { key: 'wins', label: 'Won' },
        { key: 'losses', label: 'Lost' },
      ],
      playersFields: [
        { key: 'player', label: 'Player', class: 'text-left' },
        { key: 'agent', label: 'Agent', class: 'text-left' },
      ],
    }
  },
  filters: {
    percentage,
  },
  methods: {
    getPlayerAgentHash(player) {
      return player.id + player.agent_id
    },
    toggleDetails(item) {
      item._showDetails = !item._showDetails
    },
  },
}
</script>

<style lang="scss" scoped>
.agent-icon {
  height: 40px;
}

.composition-cell {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.toggle-details-icon {
  margin-right: 1em;
  transition: 0.2s;
}

.collapsed {
  transform: rotate(0);
}

.expanded {
  transform: rotate(90deg);
}

::v-deep {
  .cursor-pointer:not(.b-table-details) {
    cursor: pointer;
  }

  &.table-hover .table-dark:hover {
    background-color: #131313;
  }

  td,
  th {
    text-align: left;
  }
}
</style>
