<template>
  <div>
    <SearchStateController :default="defaultFilters" :s="s" :params="searchParams" class="mt-4">
      <template #default="{ state }">
        <SearchFiltersProfile :compositions="currentCompositions" :team_id="teamId" :value="state" @update="onSearch" />
        <hr />
        <TeamStats :query="state" :team-data="teamData" />
      </template>
    </SearchStateController>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import SearchStateController from '@/components/controllers/SearchStateController.vue'
import SearchFiltersProfile from '@/components/Search/FiltersProfile.vue'
import mixpanel from '@/mixpanel'
import { serializeQuery } from '@/utils/searchQuery'
import TeamStats from '@/views/TeamNew/TeamStats.vue'

export default {
  name: 'TeamStatsMain',
  components: {
    SearchStateController,
    TeamStats,
    SearchFiltersProfile,
  },
  inject: ['teamData'],
  data() {
    return {
      loading: 0,
      hasMapData: true,
      statsData: {},
    }
  },
  computed: {
    ...mapGetters({
      defaultFilters: 'search/teamMatchSearchFilters',
    }),

    currentCompositions() {
      return this.teamData?.generalStats?.agent_comps_stats
        ?.filter(({ map, agent_comp }) => this.searchParams.map_ids.includes(map) && agent_comp.length)
        .map(({ agent_comp, picks }) => Object.freeze(Object.assign([...agent_comp], { picks })))
    },

    s() {
      return this.$route.query.s
    },

    searchParams() {
      return {
        map_ids: [this.$route.params.map_id],
        team_ids: [this.$route.params.team_id],
      }
    },

    teamId() {
      return this.$route.params.team_id
    },
  },
  methods: {
    ...mapMutations({
      resetFilters: 'search/RESET_FILTERS',
      saveFilters: 'search/SET_FILTERS',
    }),

    async onSearch(query) {
      this.loading++
      try {
        const serializedQuery = await serializeQuery(query)
        if (query == null) {
          this.resetFilters()
        } else {
          this.saveFilters(query)
        }
        if (this.s !== serializedQuery) {
          await this.$router.push({ query: { s: serializedQuery != null ? serializedQuery : undefined } })
        }
      } finally {
        this.loading--
      }

      mixpanel.track_search(query, query ? 'Search' : 'Reset')
    },
  },
}
</script>

<style lang="scss" scoped></style>
