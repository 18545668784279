import { isTradeKill } from '@/utils/killfeed.js'

export const calcTeamStats = (agentsData, weapons, info, generalStats, advancedStats, matches, mapData) => {
  const compositions = calcCompositions(agentsData, info.id, matches)
  const playerAgents = calcPlayerAgents(agentsData, info.id, matches)
  const roundPerformance = calcRoundPerformance(playerAgents, info.id, matches)
  const searchGeneralStats = calcGeneralStats(info.id, matches)
  const noPlantPerformance = calcNoPlantPerformance(info.id, matches)
  const winConditions = calcWinConditions(advancedStats)
  const weaponsUsage = calcWeaponsUsage(weapons, advancedStats)
  const plantPerformance = calcPlantPerformance(advancedStats)

  return {
    matches,
    generalStats: searchGeneralStats,
    compositions,
    playerAgents,
    win_conditions: winConditions,
    // performance: this.performance,
    roundPerformance,
    map: mapData,
    noPlantPerformance: Object.freeze(Object.values(noPlantPerformance)),
    weaponsUsage,
    plantPerformance,
  }
}

const calcCompositions = (agentsData, teamId, matches) => {
  return matches?.map(match => {
    const teamData = match.teams_through?.find(team => team?.team_expand?.id === teamId)
    return {
      agentIds: teamData.agent_ids,
      win: teamData.win,
      players: match.participants_through
        ?.filter(participant => participant.team === teamId)
        ?.map(player => {
          return {
            match_id: match.id,
            agent_name: agentsData[player.agent]?.name,
            agent_display_icon_url: agentsData[player.agent]?.display_icon_url,
            ...player,
            ...match.riotData?.players?.find(riotPlayer => riotPlayer.puuid === player.puuid),
          }
        }),
    }
  })
}

const calcPlayerAgents = (agentsData, teamId, matches) => {
  const playerAgents = []
  matches.forEach(match => {
    match.participants_through
      ?.filter(participant => participant.team === teamId)
      ?.forEach(player => {
        playerAgents.push({
          match_id: match.id,
          agent_name: agentsData[player.agent]?.name,
          agent_display_icon_url: agentsData[player.agent]?.display_icon_url,
          ...player,
          ...match.riotData?.players?.find(riotPlayer => riotPlayer.puuid === player.puuid),
        })
      })
  })
  return playerAgents
}

const calcRoundPerformance = (players, teamId, matches) => {
  const roundPerformance = {
    firstBlood: {
      atk: {
        wins: 0,
        total: 0,
      },
      def: {
        wins: 0,
        total: 0,
      },
      ecoItems: {},
    },
    firstDeath: {
      atk: {
        wins: 0,
        total: 0,
      },
      def: {
        wins: 0,
        total: 0,
      },
      ecoItems: {},
    },
    trade: {
      atk: {
        deaths: 0,
        total: 0,
      },
      def: {
        deaths: 0,
        total: 0,
      },
      ecoItems: {},
    },
    antiEco: {
      atk: {
        wins: 0,
        total: 0,
      },
      def: {
        wins: 0,
        total: 0,
      },
      ecoItems: {},
    },
    eco: {
      atk: {
        wins: 0,
        total: 0,
      },
      def: {
        wins: 0,
        total: 0,
      },
      ecoItems: {},
    },
    fullBuy: {
      atk: {
        wins: 0,
        total: 0,
      },
      def: {
        wins: 0,
        total: 0,
      },
      ecoItems: {},
    },
  }
  matches?.forEach(match => {
    const teamData = match.teams_through?.find(team => team?.team_expand?.id === teamId)

    match.riotData?.rounds?.forEach(roundData => {
      const team = roundData?.team_stats?.find(team => team.side === teamData.team_side)
      team.win = team.side === roundData.winning_team_side
      const oppTeam = roundData?.team_stats?.find(team => team.side !== teamData.team_side)
      oppTeam.win = oppTeam.side === roundData.winning_team_side
      if (roundData.kills && roundData.kills.length > 0) {
        const firstKill = roundData.kills?.sort((a, b) => a.round_time_millis - b.round_time_millis)[0]

        if (players.some(player => player.puuid === firstKill.killer_puuid)) {
          // check if first blood was given by this team
          updateRoundPerformanceItem(roundPerformance.firstBlood, team)
        } else {
          updateRoundPerformanceItem(roundPerformance.firstDeath, team)
        }

        let trades = 0
        let deaths = roundData.kills.filter(kill => players.some(player => player.puuid === kill.victim_puuid)).length
        roundData.kills.forEach(kill => {
          if (players.some(player => player.puuid === kill.victim_puuid)) {
            if (isTradeKill(kill, roundData.kills)) {
              trades++
            }
          }
        })

        roundPerformance.trade.ecoItems[team.eco_type] = roundPerformance.trade.ecoItems[team.eco_type] || {
          atk: {
            deaths: 0,
            total: 0,
          },
          def: {
            deaths: 0,
            total: 0,
          },
        }

        if (team.role?.toLowerCase() === 'atk') {
          roundPerformance.trade.atk.total += trades
          roundPerformance.trade.atk.deaths += deaths
          roundPerformance.trade.ecoItems[team.eco_type].atk.total += trades
          roundPerformance.trade.ecoItems[team.eco_type].atk.deaths += deaths
        } else {
          roundPerformance.trade.def.total += trades
          roundPerformance.trade.def.deaths += deaths
          roundPerformance.trade.ecoItems[team.eco_type].def.total += trades
          roundPerformance.trade.ecoItems[team.eco_type].def.deaths += deaths
        }
      }

      // Check for anti eco
      if (team.eco_type === '$$$$' && oppTeam.eco_type !== '$$$$') {
        updateRoundPerformanceItem(roundPerformance.antiEco, team)
      }

      // Check for eco
      if (team.eco_type !== '$$$$' && oppTeam.eco_type === '$$$$') {
        updateRoundPerformanceItem(roundPerformance.eco, team)
      }

      // Check for full buy
      if (team.eco_type === '$$$$' && oppTeam.eco_type === '$$$$') {
        updateRoundPerformanceItem(roundPerformance.fullBuy, team)
      }
    })
  })

  return [
    {
      scenario: 'First blood convertion(5v4)',
      title: 'Percentage of won rounds when the team made the first blood',
      ...roundPerformance.firstBlood,
      ecoItems: Object.entries(roundPerformance.firstBlood.ecoItems).map(([eco, item]) => {
        return { eco: eco, ...item }
      }),
    },
    {
      scenario: 'First death convertion(4v5)',
      title: 'Percentage of won rounds when the team gave the first death',
      ...roundPerformance.firstDeath,
      ecoItems: Object.entries(roundPerformance.firstDeath.ecoItems).map(([eco, item]) => {
        return { eco: eco, ...item }
      }),
    },
    {
      scenario: 'Trade rate',
      title: 'Percentage of trade kill from all deaths',
      ...roundPerformance.trade,
      ecoItems: Object.entries(roundPerformance.trade.ecoItems).map(([eco, item]) => {
        return { eco: eco, ...item }
      }),
    },
    {
      scenario: 'Anti eco convertion',
      title: 'Percentage of won rounds when the team is full-buy vs non full-buy',
      ...roundPerformance.antiEco,
      ecoItems: Object.entries(roundPerformance.antiEco.ecoItems).map(([eco, item]) => {
        return { eco: eco, ...item }
      }),
    },
    {
      scenario: 'Eco convertion',
      title: 'Percentage of won rounds when the team is not full-buy vs full-buy',
      ...roundPerformance.eco,
      ecoItems: Object.entries(roundPerformance.eco.ecoItems).map(([eco, item]) => {
        return { eco: eco, ...item }
      }),
    },
    {
      scenario: 'Full buy convertion',
      title: 'Percentage of won rounds when the team is full-buy vs full-buy',
      ...roundPerformance.fullBuy,
      ecoItems: Object.entries(roundPerformance.fullBuy.ecoItems).map(([eco, item]) => {
        return { eco: eco, ...item }
      }),
    },
  ]
}

const updateRoundPerformanceItem = (item, team) => {
  item.ecoItems[team.eco_type] = item.ecoItems[team.eco_type] || {
    atk: {
      wins: 0,
      total: 0,
    },
    def: {
      wins: 0,
      total: 0,
    },
  }

  if (team.role?.toLowerCase() === 'atk') {
    item.atk.total += 1
    item.atk.wins += team.win ? 1 : 0
    item.ecoItems[team.eco_type].atk.total += 1
    item.ecoItems[team.eco_type].atk.wins += team.win ? 1 : 0
  } else {
    item.def.total += 1
    item.def.wins += team.win ? 1 : 0
    item.ecoItems[team.eco_type].def.total += 1
    item.ecoItems[team.eco_type].def.wins += team.win ? 1 : 0
  }
}

const calcGeneralStats = (teamId, matches) => {
  const stats = {
    wins: 0,
    losses: 0,
    rounds_atk_won: 0,
    rounds_atk_lost: 0,
    rounds_atk_won_perc: 0,
    rounds_def_won: 0,
    rounds_def_lost: 0,
    rounds_def_won_perc: 0,
  }
  matches.forEach(match => {
    const matchTeam = match?.teams_through?.find(team => team?.team_expand?.id === teamId)
    stats.wins += matchTeam?.win ? 1 : 0
    stats.losses += matchTeam?.win ? 0 : 1
    stats.rounds_atk_won += matchTeam?.rounds_atk_won
    stats.rounds_atk_lost += matchTeam?.rounds_atk_lost
    stats.rounds_def_won += matchTeam?.rounds_def_won
    stats.rounds_def_lost += matchTeam?.rounds_def_lost
  })

  return stats
}

const calcNoPlantPerformance = (teamId, matches) => {
  const noPlantPerformance = []

  matches?.forEach(match => {
    const teamData = match?.teams_through?.find(team => team?.team_expand?.id === teamId)

    match.riotData.rounds?.forEach(roundData => {
      if (roundData.plant_side) {
        return
      }
      const team = roundData?.team_stats?.find(team => team.side === teamData.team_side)

      if (team.eco_type) {
        noPlantPerformance[team.eco_type] = noPlantPerformance[team.eco_type] || {
          atk: {
            wins: 0,
            plants: 0,
            losses: 0,
          },
          def: {
            wins: 0,
            plants: 0,
            losses: 0,
          },
          scenario: team.eco_type,
          bombsite: 'No plant',
        }
        if (team.role?.toLowerCase() === 'atk') {
          noPlantPerformance[team.eco_type].atk.wins += team.win ? 1 : 0
          noPlantPerformance[team.eco_type].atk.losses += team.win ? 0 : 1
          noPlantPerformance[team.eco_type].atk.plants += 1
        } else {
          noPlantPerformance[team.eco_type].def.wins += team.win ? 1 : 0
          noPlantPerformance[team.eco_type].def.losses += team.win ? 0 : 1
          noPlantPerformance[team.eco_type].def.plants += 1
        }
      }
    })
  })

  return noPlantPerformance
}

const calcWinConditions = advancedStats => {
  return advancedStats.condition_stats?.map(condition => {
    return {
      atk_loses: condition.rounds_atk_lost,
      atk_loses_percentage:
        Math.round((condition.rounds_atk_lost / (condition.rounds_atk_lost + condition.rounds_atk_won)) * 100) || 0,
      atk_wins: condition.rounds_atk_won,
      atk_wins_percentage:
        Math.round((condition.rounds_atk_won / (condition.rounds_atk_lost + condition.rounds_atk_won)) * 100) || 0,
      bombsite: condition.plant_site ? condition.plant_site : 'No plant',
      condition: condition.result_code,
      def_loses: condition.rounds_def_lost,
      def_loses_percentage:
        Math.round((condition.rounds_def_lost / (condition.rounds_def_lost + condition.rounds_def_won)) * 100) || 0,
      def_wins: condition.rounds_def_won,
      def_wins_percentage:
        Math.round((condition.rounds_def_won / (condition.rounds_def_lost + condition.rounds_def_won)) * 100) || 0,
    }
  })
}

// TODO - implement with real data
const calcWeaponsUsage = (weapons, advancedStats) => {
  return advancedStats?.weapons_usage?.map(weapon => {
    const weapon_name = weapons[weapon.weapon]?.name
    const weapon_image_url = weapons[weapon.weapon]?.display_icon_url
    const picks = weapon.rounds_used
    const kills = weapon.kills

    return {
      weapon_name,
      weapon_image_url,
      picks,
      kills,
      rounds_played: advancedStats.total_rounds_played,
      pick_percentage: `${Math.round((picks / advancedStats.total_rounds_played) * 100)}%`,
    }
  })
}

const calcPlantPerformance = advancedStats => {
  return advancedStats?.plants_breakdown
}
