<template>
  <div>
    <h2>Performance</h2>

    <TableWinConditions v-if="statsData.win_conditions" class="mb-5" :data="statsData.win_conditions" />

    <PostPlantPerformance v-if="false" :data="statsData" />

    <TeamRoundPerformance v-if="statsData.roundPerformance" :data="statsData.roundPerformance" />
  </div>
</template>

<script>
import px from 'vue-types'

import PostPlantPerformance from '@/components/Table/PostPlantPerformance.vue'
import TeamRoundPerformance from '@/components/Table/TeamRoundPerformance.vue'
import TableWinConditions from '@/components/Table/WinConditions.vue'

export default {
  name: 'PerformanceStats',
  components: {
    TableWinConditions,
    PostPlantPerformance,
    TeamRoundPerformance,
  },
  props: {
    statsData: px.object.def({}),
  },
}
</script>

<style lang="scss" scoped></style>
