<template>
  <div>
    <AppTable
      :items="generalStatsItems"
      :fields="mapStatsFields"
      tbody-tr-class="cursor-pointer"
      @row-clicked="toggleDetails"
    >
      <template #cell(wins)="field">
        <BIconCaretRightFill :class="field.detailsShowing ? 'expanded' : 'collapsed'" class="toggle-details-icon" />
        {{ field.item.wins }}
      </template>
      <template #cell(rounds_atk_won_perc)="field">
        {{ field.item.rounds_atk_won | percentage(field.item.rounds_atk_won + field.item.rounds_atk_lost) }}
      </template>
      <template #cell(rounds_def_won_perc)="field">
        {{ field.item.rounds_def_won | percentage(field.item.rounds_def_won + field.item.rounds_def_lost) }}
      </template>

      <template #row-details>
        <AppTable
          :items="aggregated"
          :fields="fields"
          sort-by="bombsite"
          :sort-desc="false"
          tbody-tr-class="cursor-pointer"
          @row-clicked="toggleDetails"
        >
          <template #thead-top>
            <b-tr>
              <b-th></b-th>
              <b-th colspan="3">Atk</b-th>
              <b-th colspan="3">Def</b-th>
            </b-tr>
          </template>

          <template #cell(bombsite)="field">
            <BIconCaretRightFill :class="field.detailsShowing ? 'expanded' : 'collapsed'" class="toggle-details-icon" />
            {{ field.item.bombsite | bombsite }}
          </template>

          <template #cell(atk_wins)="field">
            <div>
              <strong>{{ field.item.atk_wins }}</strong>
              <span class="text-muted small ml-1">{{ field.item.atk_wins | percentage(field.item.atk_rounds) }}</span>
            </div>
          </template>

          <template #cell(atk_losses)="field">
            <div>
              <strong>{{ field.item.atk_losses }}</strong>
              <span class="text-muted small ml-1">{{ field.item.atk_losses | percentage(field.item.atk_rounds) }}</span>
            </div>
          </template>

          <template #cell(def_wins)="field">
            <div>
              <strong>{{ field.item.def_wins }}</strong>
              <span class="text-muted small ml-1">{{ field.item.def_wins | percentage(field.item.def_rounds) }}</span>
            </div>
          </template>

          <template #cell(def_losses)="field">
            <div>
              <strong>{{ field.item.def_losses }}</strong>
              <span class="text-muted small ml-1">{{ field.item.def_losses | percentage(field.item.def_rounds) }}</span>
            </div>
          </template>

          <template #row-details="row">
            <AppTable
              :items="row.item.items"
              :fields="detailFields"
              sort-by="scenario"
              :sort-desc="true"
              :sort-compare="sortCompare"
              thead-class="-d-none"
            >
              <template #cell(atk_rounds)="field">
                <div>
                  <span>{{ field.item.atk.rounds }}</span>
                </div>
              </template>

              <template #cell(atk_wins)="field">
                <div>
                  <strong>{{ field.item.atk.wins }}</strong>
                  <span class="text-muted small ml-1">{{
                    field.item.atk.wins | percentage(field.item.atk.plants)
                  }}</span>
                </div>
              </template>

              <template #cell(atk_losses)="field">
                <div>
                  <strong>{{ field.item.atk.losses }}</strong>
                  <span class="text-muted small ml-1">{{
                    field.item.atk.losses | percentage(field.item.atk.plants)
                  }}</span>
                </div>
              </template>

              <template #cell(def_rounds)="field">
                <div>
                  <span>{{ field.item.def.rounds }}</span>
                </div>
              </template>

              <template #cell(def_wins)="field">
                <div>
                  <strong>{{ field.item.def.wins }}</strong>
                  <span class="text-muted small ml-1">{{
                    field.item.def.wins | percentage(field.item.def.plants)
                  }}</span>
                </div>
              </template>

              <template #cell(def_losses)="field">
                <div>
                  <strong>{{ field.item.def.losses }}</strong>
                  <span class="text-muted small ml-1">{{
                    field.item.def.losses | percentage(field.item.def.plants)
                  }}</span>
                </div>
              </template>
            </AppTable>
          </template>
        </AppTable>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BIconCaretRightFill } from 'bootstrap-vue'
import px from 'vue-types'

import AppTable from '@/components/generic/Table.vue'
import stringCompare from '@/utils/stringCompare'

import bombsite from '../../filters/bombsite.js'
import percentage from '../../filters/percentage.js'

const ECO_SCENARIO_ORDER = {
  P: 1,
  $: 2,
  $$: 3,
  $$$: 4,
  $$$$: 5,
}

// Helper function to stringify the values of an Object
function toString(value) {
  if (value === null || typeof value === 'undefined') {
    return ''
  } else if (value instanceof Object) {
    return Object.keys(value)
      .sort()
      .map(key => toString(value[key]))
      .join(' ')
  } else {
    return String(value)
  }
}

export default {
  name: 'PostPlantPerformanceTable',

  components: {
    AppTable,
    BIconCaretRightFill,
  },

  props: {
    data: px.object.def({}),
  },

  data() {
    return {
      fields: [
        { key: 'bombsite', label: 'Bombsite', sortable: true, class: 'text-left' },
        { key: 'atk_rounds', label: 'Rounds', sortable: true },
        { key: 'atk_wins', label: 'Wins', sortable: true },
        { key: 'atk_losses', label: 'Losses', sortable: true },
        { key: 'def_rounds', label: 'Rounds', sortable: true },
        { key: 'def_wins', label: 'Wins', sortable: true },
        { key: 'def_losses', label: 'Losses', sortable: true },
      ],
      detailFields: [
        { key: 'scenario', label: 'Economy', sortable: true },
        { key: 'atk_rounds', label: 'Rounds', sortable: true },
        { key: 'atk_wins', label: 'Wins', sortable: true },
        { key: 'atk_losses', label: 'Losses', sortable: true },
        { key: 'def_rounds', label: 'Rounds', sortable: true },
        { key: 'def_wins', label: 'Wins', sortable: true },
        { key: 'def_losses', label: 'Losses', sortable: true },
      ],
      mapStatsFields: [
        { key: 'wins', label: 'Wins' },
        { key: 'losses', label: 'Losses' },
        { key: 'rounds_atk_won', label: 'Atk Round Wins' },
        { key: 'rounds_atk_lost', label: 'Atk Round loses' },
        { key: 'rounds_atk_won_perc', label: 'Atk Round Win perc' },
        { key: 'rounds_def_won', label: 'Def Round Wins' },
        { key: 'rounds_def_lost', label: 'Def Round loses' },
        { key: 'rounds_def_won_perc', label: 'Def Round Win perc' },
      ],
    }
  },

  filters: {
    bombsite,
    percentage,
  },

  computed: {
    aggregated() {
      const aggregated = Object.entries(
        [...this.data?.performance, ...this.data?.noPlantPerformance].reduce((hash, item) => {
          const key = item.bombsite
          hash[key] = hash[key] || []
          hash[key].push(item)
          return hash
        }, {})
      )
        .map(([bombsite, items]) => {
          let atk_rounds = 0
          let atk_wins = 0
          let atk_losses = 0
          let def_rounds = 0
          let def_wins = 0
          let def_losses = 0

          for (const item of items) {
            atk_rounds += item.atk.plants
            atk_wins += item.atk.wins
            atk_losses += item.atk.losses
            def_rounds += item.def.plants
            def_wins += item.def.wins
            def_losses += item.def.losses
          }

          return {
            bombsite,
            atk_rounds,
            atk_wins,
            atk_losses,
            def_rounds,
            def_wins,
            def_losses,
            items,
            _showDetails: false,
          }
        })
        .sort(stringCompare({ picker: item => item.bombsite }))
      if (aggregated && aggregated.length > 0) {
        aggregated[0]._showDetails = true
      }
      return aggregated
    },
    generalStatsItems() {
      return [{ ...this.data.generalStats, _showDetails: true }]
    },
  },

  methods: {
    sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      const a = aRow[key]
      const b = bRow[key]

      if (key === 'scenario') {
        return ECO_SCENARIO_ORDER[a] - ECO_SCENARIO_ORDER[b]
      }

      if ((typeof a === 'number' && typeof b === 'number') || (a instanceof Date && b instanceof Date)) {
        // If both compared fields are native numbers or both are native dates
        return a < b ? -1 : a > b ? 1 : 0
      } else {
        // Otherwise stringify the field data and use String.prototype.localeCompare
        return toString(a).localeCompare(toString(b), compareLocale, compareOptions)
      }
    },

    toggleDetails(item) {
      item._showDetails = !item._showDetails
    },
  },
}
</script>

<style lang="scss" scoped>
.toggle-details-icon {
  margin-right: 1em;
  transition: 0.2s;
}

.collapsed {
  transform: rotate(0);
}

.expanded {
  transform: rotate(90deg);
}

::v-deep {
  .cursor-pointer:not(.b-table-details) {
    cursor: pointer;
  }

  &.table-hover .table-dark:hover {
    background-color: #131313;
  }

  td {
    white-space: nowrap !important;
  }
}
</style>
