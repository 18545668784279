<template>
  <div>
    <b-table :fields="fields" :items="data" sort-by="bombsite" hover striped dark>
      <template #thead-top>
        <b-tr>
          <b-th colspan="5"> Win conditions </b-th>
        </b-tr>
      </template>

      <template #cell(bombsite)="field">
        {{ field.item.bombsite | bombsite }}
      </template>
      <template #cell(condition)="field">
        {{ field.item.condition | outcome }}
      </template>
      <template #cell(atk)="field">
        <div>
          <strong>{{ field.item.atk_wins }}</strong>
          <span class="text-muted small ml-1">{{ field.item.atk_wins_percentage }}%</span>
          /
          <strong>{{ field.item.atk_loses }}</strong>
          <span class="text-muted small ml-1">{{ field.item.atk_loses_percentage }}%</span>
        </div>
      </template>
      <template #cell(def)="field">
        <div>
          <strong>{{ field.item.def_wins }}</strong>
          <span class="text-muted small ml-1">{{ field.item.def_wins_percentage }}%</span>
          /
          <strong>{{ field.item.def_loses }}</strong>
          <span class="text-muted small ml-1">{{ field.item.def_loses_percentage }}%</span>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import bombsite from '../../filters/bombsite.js'
import outcome from '../../filters/outcome.js'

export default {
  props: {
    data: Array,
  },
  data() {
    return {
      fields: [
        { key: 'bombsite', label: 'Bombsite', sortable: true },
        { key: 'condition', label: 'Condition', sortable: true },
        { key: 'atk', label: 'Attacking W/L' },
        { key: 'def', label: 'Defending W/L' },
      ],
    }
  },

  filters: {
    bombsite,
    outcome,
  },
}
</script>
