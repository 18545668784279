<template>
  <AppTable
    :items="tableItems"
    :fields="fields"
    :sort-desc="false"
    tbody-tr-class="cursor-pointer"
    variant="performance"
    @row-clicked="toggleDetails"
  >
    <template #thead-top>
      <b-tr>
        <b-th colspan="3"> Round Performance </b-th>
      </b-tr>
    </template>

    <template #cell(scenario)="field">
      <div :title="field.item.title" v-b-tooltip.hover.noninteractive>
        <BIconCaretRightFill :class="field.detailsShowing ? 'expanded' : 'collapsed'" class="toggle-details-icon" />
        {{ field.item.scenario }}
      </div>
    </template>

    <template #cell(atk)="field">
      <div v-if="field.item.scenario === 'Trade rate'">
        <strong>{{ field.item.atk.total | percentage(field.item.atk.deaths) }}</strong>
        <span v-if="field.item.atk.deaths" class="text-muted small ml-1"
          >({{ field.item.atk.total }}/{{ field.item.atk.deaths }})</span
        >
      </div>
      <div v-else>
        <strong>{{ field.item.atk.wins | percentage(field.item.atk.total) }}</strong>
        <span v-if="field.item.atk.total" class="text-muted small ml-1"
          >({{ field.item.atk.wins }}/{{ field.item.atk.total }})</span
        >
      </div>
    </template>

    <template #cell(def)="field">
      <div v-if="field.item.scenario === 'Trade rate'">
        <strong>{{ field.item.def.total | percentage(field.item.def.deaths) }}</strong>
        <span v-if="field.item.def.deaths" class="text-muted small ml-1"
          >({{ field.item.def.total }}/{{ field.item.def.deaths }})</span
        >
      </div>
      <div v-else>
        <strong>{{ field.item.def.wins | percentage(field.item.def.total) }}</strong>
        <span v-if="field.item.def.total" class="text-muted small ml-1"
          >({{ field.item.def.wins }}/{{ field.item.def.total }})</span
        >
      </div>
    </template>

    <template #row-details="row">
      <AppTable
        :items="row.item.ecoItems"
        :fields="detailFields"
        sort-by="eco"
        :sort-desc="true"
        :sort-compare="sortCompare"
        thead-class="-d-none"
      >
        <template #cell(atk)="field">
          <div v-if="field.item.scenario === 'Trade rate'">
            <strong>{{ field.item.atk.total | percentage(field.item.atk.deaths) }}</strong>
            <span v-if="field.item.atk.deaths" class="text-muted small ml-1"
              >({{ field.item.atk.total }}/{{ field.item.atk.deaths }})</span
            >
          </div>
          <div v-else>
            <strong>{{ field.item.atk.wins | percentage(field.item.atk.total) }}</strong>
            <span v-if="field.item.atk.total" class="text-muted small ml-1"
              >({{ field.item.atk.wins }}/{{ field.item.atk.total }})</span
            >
          </div>
        </template>

        <template #cell(def)="field">
          <div v-if="field.item.scenario === 'Trade rate'">
            <strong>{{ field.item.def.total | percentage(field.item.def.deaths) }}</strong>
            <span v-if="field.item.def.deaths" class="text-muted small ml-1"
              >({{ field.item.def.total }}/{{ field.item.def.deaths }})</span
            >
          </div>
          <div v-else>
            <strong>{{ field.item.def.wins | percentage(field.item.def.total) }}</strong>
            <span v-if="field.item.def.total" class="text-muted small ml-1"
              >({{ field.item.def.wins }}/{{ field.item.def.total }})</span
            >
          </div>
        </template>
      </AppTable>
    </template>
  </AppTable>
</template>

<script>
import { BIconCaretRightFill } from 'bootstrap-vue'

import AppTable from '@/components/generic/Table.vue'

import percentage from '../../filters/percentage.js'

const ECO_SCENARIO_ORDER = {
  P: 1,
  $: 2,
  $$: 3,
  $$$: 4,
  $$$$: 5,
}

// Helper function to stringify the values of an Object
function toString(value) {
  if (value === null || typeof value === 'undefined') {
    return ''
  } else if (value instanceof Object) {
    return Object.keys(value)
      .sort()
      .map(key => toString(value[key]))
      .join(' ')
  } else {
    return String(value)
  }
}

export default {
  name: 'TeamRoundPerformanceTable',

  components: {
    AppTable,
    BIconCaretRightFill,
  },

  props: {
    data: Array,
  },
  data() {
    return {
      fields: [
        { key: 'scenario', label: 'Scenario', sortable: false },
        { key: 'atk', label: 'Atk', sortable: false },
        { key: 'def', label: 'Def', sortable: false },
      ],
      detailFields: [
        { key: 'eco', label: 'Economy', sortable: true },
        { key: 'atk', label: 'Atk', sortable: true },
        { key: 'def', label: 'Def', sortable: true },
      ],
    }
  },

  filters: {
    percentage,
  },

  computed: {
    tableItems() {
      return this.data.map((item, idx) => {
        return {
          ...item,
          ecoItems: item.ecoItems.map(ecoItem => {
            return {
              ...ecoItem,
              scenario: item.scenario,
            }
          }),
          _showDetails: idx === 0 ? true : false,
        }
      })
    },
  },

  methods: {
    sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      const a = aRow[key]
      const b = bRow[key]

      if (key === 'eco') {
        return ECO_SCENARIO_ORDER[a] - ECO_SCENARIO_ORDER[b]
      }

      if ((typeof a === 'number' && typeof b === 'number') || (a instanceof Date && b instanceof Date)) {
        // If both compared fields are native numbers or both are native dates
        return a < b ? -1 : a > b ? 1 : 0
      } else {
        // Otherwise stringify the field data and use String.prototype.localeCompare
        return toString(a).localeCompare(toString(b), compareLocale, compareOptions)
      }
    },

    toggleDetails(item) {
      item._showDetails = !item._showDetails
    },
  },
}
</script>

<style lang="scss" scoped>
.toggle-details-icon {
  margin-right: 1em;
  transition: 0.2s;
}

.collapsed {
  transform: rotate(0);
}

.expanded {
  transform: rotate(90deg);
}

::v-deep {
  .cursor-pointer:not(.b-table-details) {
    cursor: pointer;
  }

  &.table-hover .table-dark:hover {
    background-color: #131313;
  }

  td,
  th {
    text-align: left;
  }
}
</style>
