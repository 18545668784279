<template>
  <div>
    <div v-if="loading" class="flex-fill d-flex justify-content-center mt-4 mb-4">Loading...</div>

    <div v-else>
      <div v-if="hasMapData">
        <CompositionsStats :stats-data="statsData" />

        <PerformanceStats :stats-data="statsData" />

        <!-- Temporary disabled until verify api data -->
        <ChartPlantPerformance v-if="!hideBrokenStuff" class="mb-5" :stats-data="statsData" />

        <!-- TODO need api update from Mori -->
        <!-- <TableRoundPerformance
          class="mb-5"
          :data="null"
        /> -->

        <TableWeaponStats class="mb-5" :data="statsData.weaponsUsage" :show_pick_percentage="false" />
      </div>

      <div v-else class="text-center text-muted mt-5 mb-5">No map data</div>
    </div>
  </div>
</template>

<script>
import px from 'vue-types'
import { mapGetters } from 'vuex'

import { getMatchRiotData, getMatchInfo } from '@/api/matches/index.js'
import { findMatches } from '@/api/search.js'
import { getTeamStats } from '@/api/teams.js'
import ChartPlantPerformance from '@/components/Chart/PlantPerformance.vue'
import TableWeaponStats from '@/components/Table/WeaponStats.vue'
import CompositionsStats from '@/components/team/stats/CompositionsStats.vue'
import PerformanceStats from '@/components/team/stats/PerformanceStats.vue'
import { calcTeamStats } from '@/utils/calcTeamStats.js'

export default {
  name: 'TeamStats',

  components: {
    TableWeaponStats,
    ChartPlantPerformance,
    CompositionsStats,
    PerformanceStats,
  },

  props: {
    query: px.object,
    teamData: px.object,
  },

  data: () => ({
    loading: false,
    hasMapData: true,
    statsData: {},
  }),

  computed: {
    ...mapGetters({
      agentsById: 'static/agentsById',
      getAgentById: 'static/getAgentById',
      getMapById: 'static/getMapById',
      weapons: 'static/weaponsById',
      selected_map_id: 'team/selected_map_id',
      teamId: 'team/id',
    }),
  },

  watch: {
    query: {
      immediate: true,
      deep: true,
      handler(v) {
        if (v) {
          this.loadData(v)
        }
      },
    },
  },

  methods: {
    async loadData(query) {
      this.loading = true
      try {
        const stats = await getTeamStats(this.teamData.info.id, query)
        const { matches } = await findMatches(query)
        for (let i = 0; i < matches.length; i++) {
          const match = matches[i]
          const matchInfo = await getMatchInfo({ matchId: match.id })
          matchInfo.riotData = await getMatchRiotData({ matchId: match.id })
          matches[i] = matchInfo
        }

        this.statsData = calcTeamStats(
          this.agentsById,
          this.weapons,
          this.teamData.info,
          this.teamData.generalStats,
          stats,
          matches,
          this.getMapById(this.selected_map_id)
        )
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style></style>
